<template>
  <el-card shadow="never" header class="x-card-title-slot">
    <div class="row no-gutters align-items-center" slot="header">
      <div class="col header-title">智能换货规则设置</div>
    </div>

    <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="96px" class="demo-ruleForm">
      <el-form-item prop="shop_disable">
        是否开启只能换货规则：
        <el-radio v-model="ruleForm.activate" :label="false">关闭</el-radio>
        <el-radio v-model="ruleForm.activate" :label="true">开启</el-radio>
      </el-form-item>
      <el-form-item v-if="ruleForm.activate">
        <el-checkbox name="type" v-model="ruleForm.one_activate" @change="activateChange">
          1、按照利润率上浮
          <el-input @input="isnumber($event, 'one_param_up')" v-model="ruleForm.one_param_up" size="mini"></el-input>%，下降
          <el-input @input="isnumber($event, 'one_param_down')" v-model="ruleForm.one_param_down" size="mini"></el-input>%
        </el-checkbox>
        <br />
        <el-checkbox name="type" v-model="ruleForm.two_activate" @change="activateChange">
          2、按照成本价(会员价)上浮
          <el-input @input="isnumber($event, 'two_param_up')" type="number" v-model="ruleForm.two_param_up"
            size="mini"></el-input>%，下降
          <el-input @input="isnumber($event, 'two_param_down')" type="number" v-model="ruleForm.two_param_down"
            size="mini"></el-input>%
        </el-checkbox>
        <br />
        <span class="hint">提示：设置后，系统将会按照【原商品成本价*（100-下降值）%，原商品成本价*（100+上浮值）%】的价格区间进行匹配商品。</span>
        <br />
        <el-checkbox name="type" v-model="ruleForm.three_category_activate"
          @change="activateChange">3、按照相同分类</el-checkbox>
        <br />
        <div v-if="ruleForm.one_activate || ruleForm.two_activate || ruleForm.three_category_activate">
          <el-checkbox name="type" v-model="ruleForm.four_activate" @change="ruleForm.four_param_goods_num = 50">
            4、前台展示可换取商品数
            <el-input @input="isnum" v-model="ruleForm.four_param_goods_num" :disabled="!ruleForm.four_activate"
              size="mini"></el-input>
            <span class="hint">提示：前台展示可换商品数量最低是1，最高只能设置到50。</span>
          </el-checkbox>
          <br />
          <el-checkbox name="type" v-model="ruleForm.five_activate">
            5、按照京东馆商品
            <span class="hint">提示：如未勾选该项，系统默认按照全部商品进行筛选。</span>
          </el-checkbox>
          <br />
          <el-checkbox name="type" v-model="ruleForm.six_activate">
            6、按照企业总销量排序
            <span class="hint">提示：如未勾选该项，系统默认按照商品利润率从高到低进行排序。</span>
          </el-checkbox>
        </div>
      </el-form-item>
      <div style="text-align:center">
        <el-button type="primary" @click="setRuleForm" :disabled="disabled" size="small">保存</el-button>
      </div>
    </el-form>
  </el-card>
</template>

<script>
import * as API_goods from "@/api/goods";
import { asyncRouterMap } from "@/router";
import { RegExp } from "@/../ui-utils";

export default {
  name: "intelligence",
  data () {
    return {
      ruleForm: {
        activate: false,
        one_activate: false,
        one_param_up: null,
        one_param_down: null,
        two_activate: false,
        two_param_up: 0,
        two_param_down: 0,
        three_category_activate: false,
        four_activate: false,
        four_param_goods_num: null,
        five_activate: false,
        six_activate: false
      },
      rules: {
        one_param_up: [
          { required: true, message: "请输入利润上浮率", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" }
        ]
        // region: [
        //   { required: true, message: '请选择活动区域', trigger: 'change' }
        // ],
        // date1: [
        //   { type: 'date', required: true, message: '请选择日期', trigger: 'change' }
        // ],
        // date2: [
        //   { type: 'date', required: true, message: '请选择时间', trigger: 'change' }
        // ],
        // type: [
        //   { type: 'array', required: true, message: '请至少选择一个活动性质', trigger: 'change' }
        // ],
        // resource: [
        //   { required: true, message: '请选择活动资源', trigger: 'change' }
        // ],
        // desc: [
        //   { required: true, message: '请填写活动形式', trigger: 'blur' }
        // ]
      }
    };
  },
  watch: {},
  created () {

    this.getRuleForm()
  },
  mounted () { },
  computed: {
    disabled () {
      if (
        this.ruleForm.activate &&
        !(
          this.ruleForm.one_activate ||
          this.ruleForm.two_activate ||
          this.ruleForm.three_category_activate
        )
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    isnumber (e, key) {
      e = (e.match(/\d+(.\d{0,2})?/) || [""])[0];
      if (key == 'two_param_down') {
        this.ruleForm[key] = e > 100 ? 100 : e;
      } else {
        this.ruleForm[key] = e > 100000 ? 100000 : e;
      }
      console.log(e, key);
    },
    isnum (e) {
      e = e.replace(/[^0-9]/g, "");
      if (e < 1) {
        this.ruleForm["four_param_goods_num"] = ""
      } else if (e > 50) {
        this.ruleForm["four_param_goods_num"] = 50
      }
    },
    // inputNumber(e) {
    //   console.log(e, this.ruleForm.one_param_up)
    //   // , this.ruleForm.one_param_up, this.ruleForm.one_param_up.replace(/[-]/g, '')
    //   // this.ruleForm.one_param_up = this.ruleForm.one_param_up.replace(/[-]/g, '')
    // },
    activateChange () {
      if (
        !(
          this.ruleForm.one_activate ||
          this.ruleForm.two_activate ||
          this.ruleForm.three_category_activate
        )
      ) {
        this.ruleForm.four_activate = false;
        this.ruleForm.five_activate = false;
        this.ruleForm.six_activate = false;
      }
    },
    getRuleForm () {
      API_goods.getGoodsCardRule().then(res => {
        if (res.code !== 200) return console.log("接口报错");
        this.ruleForm = res.data;
        this.ruleForm.activate = this.ruleForm.activate === 1 ? true : false;
        this.ruleForm.one_activate =
          this.ruleForm.one_activate === 1 ? true : false;
        this.ruleForm.two_activate =
          this.ruleForm.two_activate === 1 ? true : false;
        this.ruleForm.three_category_activate =
          this.ruleForm.three_category_activate === 1 ? true : false;
        this.ruleForm.four_activate =
          this.ruleForm.four_activate === 1 ? true : false;
        this.ruleForm.five_activate =
          this.ruleForm.five_activate === 1 ? true : false;
        this.ruleForm.six_activate =
          this.ruleForm.six_activate === 1 ? true : false;
        this.ruleForm.two_param_down =
          res.data.two_param_down > 100 ? 100 : res.data.two_param_down;
      });
    },
    setRuleForm () {
      let params = { ...this.ruleForm };
      for (const key in params) {
        const elem = params[key];
        console.log(params[key]);
        if (params[key] === false) {
          params[key] = 0;
        } else if (params[key] === true) {
          params[key] = 1;
          console.log(params[key]);
        } else if (params[key] === '') {
          params[key] = 0;
        }
      }
      console.log(params);
      API_goods.saveGoodsCardRule(params).then(res => {
        if (res.code !== 200) return console.log("接口报错");
        this.$message.success("保存成功");
        this.getRuleForm();
        console.log(res);
      });
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

::v-deep input[type="number"] {
  -moz-appearance: textfield !important;
}

.el-input {
  width: 80px;
  margin: 0 10px;
}

.hint {
  font-size: 12px;
  color: #a4a2a2;
}

/deep/ .el-form-item__content {
  .background-img {
    width: 167px;
    height: 167px;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }

    .mantle {
      width: 167px;
      height: 167px;
      display: none;
      position: absolute;
      top: 0;
      background-color: black;
      opacity: 0.5;
      border-radius: 10px;

      .el-icon-delete {
        font-size: 23px;
        position: relative;
        left: 85%;
      }

      .btn-change-img {
        display: block;
        margin: 0 auto;
        position: relative;
        top: 15%;
        background-color: black;
        opacity: 0.6;
        color: white;
      }
    }
  }

  .background-img:hover {
    .mantle {
      display: block;
    }
  }

  .el-color-picker {
    height: 30px;
    margin-left: 10px;
    vertical-align: middle;

    .el-color-picker__trigger {
      width: 90px;
      height: 30px;
      padding: 0;
      border: 0;
      border-radius: 5px;
    }

    .el-color-picker__color {
      border: 1px solid #cccccc;
    }

    .el-icon-arrow-down:before {
      content: "";
    }
  }
}

.content {
  background-color: white;
  border-radius: 10px;
  padding-bottom: 20px;
}

/deep/ .el-form-item {
  margin-bottom: 15px;
}

.zz {
  font-size: 18px;
  font-weight: bold;
  padding-left: 10px;
}

.shu {
  height: 30px;
  width: 6px;
  background-color: rgb(26, 67, 169);
  display: inline-block;
}

.he {
  padding-top: 30px;
  padding-bottom: 30px;
}
</style>
